//
// Configuration Manager
//
//

'use strict';

var CryptoJS = require("crypto-js");

// STATE
var gConfig = null;

function decrypt(encryptedMessage, aesKey) {
	var bytes  = CryptoJS.AES.decrypt(encryptedMessage, aesKey);
	var plaintext = bytes.toString(CryptoJS.enc.Utf8);
	return plaintext;
}

function decryptIfNeeded(obj, key, value, aesKey) {
	if ( key.endsWith('_ENCRYPTED') ) {
		// Decrypt the encrypted value
		var plaintext = decrypt(value, aesKey);
		// Replace xxx_ENCRYPTED key and encrypted value with xxx key and decrypted value
		delete obj[key];
		obj[key.substr(0, key.indexOf('_'))] = plaintext;
	}
}

function traverse(o, aesKey, func) {
	for ( var i in o ) {
		func.apply(this,[o, i, o[i], aesKey]);  
		if ( o[i] !== null && typeof(o[i]) === "object" ) {
			//going one step down in the object tree!!
			traverse(o[i], aesKey, func);
		}
	}
}

function decryptSecrets(config, aesKey) {
	traverse(config, aesKey, decryptIfNeeded);
}

function init() {
	if ( gConfig ) {
		// Singleton pattern
		return gConfig;
	}

	// Get secondAesKey (from key store / environment / NOT hard-coded)
	var secondAesKey = process.env.ACCREM_KEY;
	if ( ! secondAesKey ) {
		// If we don't find our key, return a null config and the main index.js code will abort
		return null;
	}
	// Read config.json file
	var config = require('./config.json');
	// Get ahold of config.key value (this is the firstAesKey value encrypted by the secondAesKey)
	var encryptedFirstAesKey = config.key;
	// Use secondAesKey to decrypt config.key. This gives firstAesKey.
	var firstAesKey = decrypt(encryptedFirstAesKey, secondAesKey);
	// Use firstAesKey to decrypt all other keys in config object that need decrypting
	decryptSecrets(config, firstAesKey);

	gConfig = config; // Stash away for efficiency; will be returned immediately from now on
	return config;
}

module.exports.init = init;
