<template>
	<div id="asl-consent-page" class="page">
		<Menu></Menu>
		<RemoteHeader :heading="$t('signLanguagePageHeadingLabel')"></RemoteHeader>
		<ASLRemoteHeaderButtonContainer>
			<ASLCloseButton />
		</ASLRemoteHeaderButtonContainer>
		<ASLGettingStarted />
</div>
</template>

<script>
import RemoteHeader from "@/components/RemoteHeader.vue";
import Menu from '@/components/Menu.vue';
import ASLGettingStarted from '@/components/ASLGettingStarted.vue';
import ASLRemoteHeaderButtonContainer from "@/components/ASLRemoteHeaderButtonContainer.vue";
import ASLCloseButton from "@/components/ASLCloseButton.vue";

export default {
	data() {
		return {}
	},
	name: "ASLGettingStartedPage",
	components: {
		RemoteHeader,
		Menu,
		ASLGettingStarted,
		ASLRemoteHeaderButtonContainer,
		ASLCloseButton
	},
	mounted: function() {
		window.focusPageHeading("h2");
		window.setPageTitle('signLanguagePageHeadingLabel');
	}
};
</script>

<style lang="scss" scoped>

</style>
