import Vue from 'vue';
import Router from 'vue-router';
import { Analytics } from './analytics.js';
// Main "pages" of the application
import HomePage from './views/HomePage.vue';
import SettingsPage from './views/SettingsPage.vue';
import WaitingForTvPage from './views/WaitingForTvPage';
import ChooseDevicePage from './views/ChooseDevicePage.vue';
import RemotePage from './views/RemotePage.vue';
import SeaButtonsPage from './views/SeaButtonsPage.vue';
import EditButtonOrderPage from './views/EditButtonOrderPage.vue';
import TVConfigPage from './views/TVConfigPage.vue';
import ErrorPage from './views/ErrorPage.vue';
import FourOhFourPage from './views/FourOhFourPage.vue';
import LearnMorePage from './views/LearnMorePage.vue';
import LoginCompletePage from './views/LoginCompletePage.vue';
import LogoutCompletePage from './views/LogoutCompletePage.vue';
import CompanionHomePage from './views/CompanionHomePage';
import CompanionSuccessPage from './views/CompanionSuccessPage';
import ChoosePopularButtonsPage from './views/ChoosePopularButtonsPage';
import EditCustomButtonPage from './views/EditCustomButtonPage';
import AddNewButtonPage from './views/AddNewButtonPage';
import IFTTTHowTo from './views/IFTTTHowTo';
import ASLInterfacePage from './views/ASLInterfacePage';
import ASLConsentPage from './views/ASLConsentPage';
import ASLGettingStartedPage from './views/ASLGettingStartedPage.vue';

Vue.use(Router);

// NOTES:
//   - The following are *server-side* routes:
//       - /login
//       - NOT!: login-complete
//       - /logout
//       - NOT!: logout-complete
//       - /api/v*/*
//       - /api/healthcheck
//       - /api/health/cf
//
//   - To generate a separate chunk (foo.[hash].js) for a route, which is lazy-loaded when the route is visited,
//     instead of using
//         component: Foo"
//     use
//         component() { return import(/* webpackChunkName: "foo" */ './views/Foo.vue');

// NOTE: Make sure this list of paths matches the paths in /src/server/index.js
const routes = [
	{ path: '/', name: 'homePage', component: HomePage },
	{ path: '/settings', name: 'settingsPage', component: SettingsPage },
	{ path: '/companion', name: 'companionHomePage', component: CompanionHomePage },
	{ path: '/companion-success', name: 'companionSuccessPage', component: CompanionSuccessPage },
	{
		path: '/add-popular-buttons',
		name: 'choosePopularButtonsPage',
		component: ChoosePopularButtonsPage,
	},
	{ path: '/login-complete', name: 'loginCompletePage', component: LoginCompletePage },
	{ path: '/logout-complete', name: 'logoutCompletePage', component: LogoutCompletePage },
	{ path: '/waiting-for-tv', name: 'waitingForTvPage', component: WaitingForTvPage },
	{ path: '/choose-device', name: 'chooseDevicePage', component: ChooseDevicePage },
	{ path: '/remote', name: 'remotePage', component: RemotePage },
	{ path: '/my-remote', name: 'seaButtonsPage', component: SeaButtonsPage },
	{ path: '/my-remote-config', name: 'editButtonOrderPage', component: EditButtonOrderPage },
	{ path: '/tv-config', name: 'tvConfigPage', component: TVConfigPage },
	{ path: '/edit-custom-button', name: 'editCustomButtonPage', component: EditCustomButtonPage },
	{ path: '/add-new-button', name: 'addNewButtonPage', component: AddNewButtonPage },
	{ path: '/learn-more', name: 'learnMorePage', component: LearnMorePage },
	{ path: '/error', name: 'errorPage', component: ErrorPage },
	{ path: '/ifttt-howto', name: 'iftttHowTo', component: IFTTTHowTo },
	{ path: '*', name: 'fourOhFourPage', component: FourOhFourPage },
	{ path: '/asl-interface', name: 'aslInterfacePage', component: ASLInterfacePage },
	{ path: '/asl-consent', name: 'aslConsentPage', component: ASLConsentPage },
	{ path: '/asl-getting-started', name: 'aslGettingStartedPage', component: ASLGettingStartedPage },
];

const arRouter = new Router({
	mode: 'history',
	base: process.env.BASE_URL,
	routes: routes,
});

arRouter.afterEach(( to, from ) => {
	Analytics.page({
		pageName: to.name,
		properties: {
			path: to.path
		},
		callback: null
	});
});

window.arRouter = arRouter; // For use within AccessibleRemote.js
export default arRouter;
