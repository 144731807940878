<template>
    <button v-show="showMe" class="btn num-btn" @click="handleAddButtonClick">
        <img class="soft-img icon" src="/images/icons/icon_addcircle_outline.svg" alt="" role="img" aria-hidden="true" />
        <div class="letters">{{ $t('editButtonOrderPageAddItem') }}</div>
    </button>
</template>
<script>
import editButtonsMixin from '../mixins/editButtons'

export default {
    props: ['showMe'],
    mixins: [editButtonsMixin],
};
</script>

<style lang="scss" scoped>
.btn.num-btn {
    & .soft-img.icon {
        margin: 0;
        margin-bottom: 0.25rem;
        max-height: 3rem;
    }
}
</style>
