<template>
	<PleaseWait></PleaseWait>
</template>

<script>
import i18nJ from '@/plugins/i18nJ';
import PleaseWait from '@/components/PleaseWait.vue';

export default {
	name: 'LoginCompletePage',
	components: {
		PleaseWait
	},
	props: [],
	data: function() {
		return {};
	},
	methods: {},
	computed: {},
	mounted: function() {
		// Handy for debugging Xerxes-related issues:
		//console.log('Xerxes redirected to: ' + window.location.href);

		const url = window.location.href.replace('#', '?');

		const err = ar.getParameterByName('error', url);

		if ( err ) {
			const errDesc = ar.getParameterByName('error_description', url);
			const activityId = ar.getParameterByName('activity_id', url);
			let msg = i18nJ.t('loginError') + ': ' + err;
			if ( errDesc ) { msg += ' (' + errDesc + ')'; }
			if ( activityId ) { msg += ' [' + activityId + ']'; }
			arRouter.push('/error?msg=' + msg);
			return;
		}

		// Pluck "state" from query string
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		const sState = urlParams.get('state') || '';

		// Parse state string, which is a URL encoded string like "foo=xxx&bar=yyy")
		let oState = {};
		if ( sState ) {
			oState = JSON.parse(
				'{"' + sState.replace(/&/g, '","').replace(/=/g,'":"') + '"}',
				function(key, value) { return key===""?value:decodeURIComponent(value) }
			);
		}

		// Handle key/supported k=v pairs within the decoded state string:
		// 1. Persist whether ASL is enabled (asl=true within state => 'true', else 'false')
		//    NOTE: "Client code" should use ar.getLocalBoolean to retrieve and use this value
		const sAsl = ( oState.hasOwnProperty('asl') ? oState.asl : 'false' );
		const enableAsl = ( sAsl === 'true' ? true : false );
		ar.saveLocal('enableAsl', ''+enableAsl);

		// Convert from code to token
		const code = ar.getParameterByName('code', url);
		let partner = ar.getPartner();
		ar.setState({ partner });
		ar.fetchTokenFromAuthCode(code);
		window.focusPageHeading();
	}
};
</script>
