<template>
	<div id="asl-consent-container">
		<h2 class="standard-setting toc-heading">Terms and conditions</h2>
		<div class="toc-contents">
			<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptas, sapiente! Labore officiis iusto impedit, incidunt repudiandae natus doloremque debitis ipsum obcaecati. Id eligendi reiciendis cumque magni nam fugiat voluptatibus vitae! Ea voluptates consequuntur nisi est accusamus illum ipsum eaque, dolor sint vitae vero quod minus quia corporis reiciendis minima omnis debitis esse quae voluptate quas dolore nemo. Doloribus, fugiat praesentium. Quis aliquam sapiente eum fugiat a beatae facere dicta corporis iste rem nam nesciunt ea voluptates pariatur aut quisquam veritatis error sed atque natus, repudiandae maxime unde sint. Et, placeat.</p>
			<p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Tempore distinctio veritatis labore vero sed quia, inventore possimus officiis quod architecto aliquid nihil quae porro, eius, accusantium repudiandae. Laborum, maiores necessitatibus? Consectetur sit, eligendi dolor reiciendis a repellat asperiores exercitationem quod provident perspiciatis, voluptatum distinctio, deleniti unde officiis! Fuga, debitis nemo similique natus earum impedit, accusamus, aliquid non voluptatem velit reiciendis. Alias totam beatae expedita eos ad! Libero mollitia recusandae non nihil consequuntur obcaecati. Qui optio officiis ut at veniam eum corrupti ipsa pariatur. Fugiat aut nulla architecto, sint sunt placeat.</p>
			<p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fuga ad cupiditate temporibus nulla et quas, aliquam impedit sint fugit dicta iure nisi eligendi hic officia earum atque accusamus numquam. Dicta. Aliquid ullam molestias sint architecto iste consequuntur vel perferendis alias cupiditate. Veniam ipsam et fuga distinctio natus soluta deserunt quasi sunt accusantium? Repellat quae repellendus ipsam, reprehenderit doloremque facilis impedit. Voluptas assumenda atque ducimus eligendi. Adipisci, asperiores reiciendis id tempore vitae rem, quasi, nemo voluptates corrupti harum alias exercitationem praesentium dolores magni. Dolorum maxime ratione quo, magnam modi excepturi doloremque.</p>
		</div>
		<div class="consent-buttons-outer">
			<div class="consent-buttons">
				<button class="standard-button primary" @click="acceptConsent">Accept terms</button>
				<button class="standard-button secondary" @click="rejectConsent">Decline terms</button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ASLConsent',
	methods: {
		rejectConsent: function () {
			ar.removeLocal('asl-consent');
			arRouter.push('/remote');
			return;
		},
		acceptConsent: function () {
			ar.saveLocal('asl-consent', 'true');
			arRouter.push('/asl-interface');
			return;
		}
	}
};
</script>

<style lang="scss" scoped>

#asl-consent-container {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.toc-heading {
	text-align: center;
	margin-bottom: 0.5rem;
}

.toc-contents {
	font-family: 'XfinityBrown-Regular', sans-serif;
	font-size: 1rem;
	line-height: 1.5rem; /* 150% */
	margin-left: 1rem;
	margin-right: 1rem;
	margin-bottom: 15rem;
	max-width: 50rem;

	& p {
		margin-bottom: 1rem;
	}
}

.consent-buttons-outer {
	background-color: $xfinity-grey-80;
	width: 100%;
	position: fixed;
	bottom: 0;
}

.consent-buttons {
	display: flex;
	padding: 1rem;
	flex-direction: column;
	row-gap: 0.5rem;
	margin: 1rem;
	& .standard-button {
		margin: unset;
	}
}

@include tp {
	.toc-contents {
		margin-left: 4rem;
		margin-right: 4rem;
	}

	.consent-buttons {
		column-gap: 1rem;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
	}
}

@include md {
	.toc-contents {
		margin-left: 7rem;
		margin-right: 7rem;
	}
}
</style>
