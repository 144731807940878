<!-- a copy of components > buttons > AddPopularButton.vue -->
<template>
	<button v-show="aslInterface" class="btn num-btn" @click="handleASLButtonClick">
		<img class="soft-img icon" src="/images/icn-asl-hand.svg" alt="">
		<div class="letters">ASL remote</div>
	</button>
</template>
<script>
import aslInterfaceMixin from '../mixins/aslInterface'
export default {
	props: ['aslInterface'],
	mixins: [aslInterfaceMixin]
};
</script>

<style lang="scss" scoped>
.btn.num-btn {
    & .soft-img.icon {
        margin: 0;
		margin-bottom: 0.25rem;
        max-height: 3rem;
    }
}
</style>