<template>
    <section aria-label="Error notification" class="notification-bar"
        :class="{ visible: visible, 'error-style': isError }" :inert="visible === false ? 'true' : null"
        :aria-hidden="visible === false ? 'true' : null">
        <div class="notification-bar-inner">
            <div class="notification-bar-message">
                <div class="icon-container">
                    <svg class="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" aria-hidden="true"
                        v-if="isError">
                        <path
                            d="M21.59,16.47l-7-12a3,3,0,0,0-5.16,0l-7,12A3,3,0,0,0,5,21H19A3,3,0,0,0,21.59,16.47ZM11,8.75h2v6.08H11Zm1.84,8.92a1.23,1.23,0,0,1-1.68,0,1.18,1.18,0,0,1,0-1.68,1.23,1.23,0,0,1,1.68,0,1.18,1.18,0,0,1,0,1.68Z" />
                    </svg>
                    <svg class="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" aria-hidden="true" v-if="!isError">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M10.42 3.5H13.58C15.8131 3.5 17.9548 4.3871 19.5338 5.96616C21.1129 7.54522 22 9.68688 22 11.92V12.08C22 14.3131 21.1129 16.4548 19.5338 18.0338C17.9548 19.6129 15.8131 20.5 13.58 20.5H10.42C8.18688 20.5 6.04522 19.6129 4.46616 18.0338C2.8871 16.4548 2 14.3131 2 12.08V11.92C2 7.26976 5.76976 3.5 10.42 3.5ZM12 9.83C12.2492 9.83661 12.4905 9.74294 12.67 9.57C12.847 9.39656 12.9447 9.15779 12.94 8.91C12.9443 8.66521 12.8465 8.42967 12.67 8.26C12.2894 7.91648 11.7106 7.91648 11.33 8.26C11.1535 8.42967 11.0557 8.66521 11.06 8.91C11.0553 9.15779 11.153 9.39656 11.33 9.57C11.5095 9.74294 11.7508 9.83661 12 9.83ZM12.75 10.92V16.5H11.25V10.92H12.75Z"
                            fill="#0051D0" />
                    </svg>
                </div>
                <div class="notification-bar-message-inner">
                    <strong>{{ title }} </strong>
                    <span class="content">{{ message }}</span>
                </div>
            </div>
            <button @click="dismiss" class="dismiss" aria-label="Close">
                <CloseIcon />
            </button>
        </div>
    </section>
</template>

<script>
import CloseIcon from './CloseIcon.vue';

export default {
    name: 'ASLNotificationBar',
    components: {
        CloseIcon
    },
    props: {
        visible: Boolean,
        title: {
            type: String,
            default: "Error"
        },
        message: String,
        isError: {
            type: Boolean,
            default: true
        },
    },
    methods: {
        dismiss() {
            this.$emit('dismiss-notification', this.isError);
        }
    }
}
</script>

<style lang="scss" scoped>
.notification-bar {
    transition: transform 300ms ease-in-out;
    box-sizing: border-box;
    position: fixed;
    bottom: 0;
    width: 100%;
    font-size: 1rem;
    line-height: 1.25;
    color: $xfinity-grey-80;
    background-color: $ar-asl-info-background-color;

    &.error-style {
        color: #fff;
        background-color: $ar-asl-error-background-color;

        & .icon {
            fill: #fff;
        }

        & .dismiss:focus {
            @include focus-outline;
        }
    }
}

.notification-bar:not(.visible) {
    transform: translateY(100%);
}

@media(prefers-reduced-motion: reduce) {
    .notification-bar {
        transition: none;
    }
}

.notification-bar-inner {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 1rem 1.5rem;
    margin-bottom: 2.5rem;
    font-family: 'XfinityBrown-Light', sans-serif;
    font-weight: 100;
    height: 100%;

    .icon-container {
        display: flex;
    }

    .icon {
        height: 1.5rem;
        width: 1.5rem;
        fill: $xfinity-grey-80;
    }

    & .dismiss {
        background-color: transparent;
        fill: #fff;
        border: none;
        margin: 0;
        padding: 0;

        &:focus {
            @include focus-outline;
            outline-color: $xfinity-grey-80;
        }
    }

    & .notification-bar-message {
        display: inline-flex;
        flex: 1;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.5rem;
        width: 100%;
        
        & .notification-bar-message-inner {
            display: flex;
            flex-direction: column;
            overflow: hidden;

            & strong {
                font-family: 'XfinityBrown-Bold', sans-serif;
                font-weight: 700;
            }
        }

    }
}

@include tp {
    .notification-bar-inner {
        margin: 1rem 4rem;

        & .notification-bar-message {
            flex-direction: row;
            align-items: flex-end;
            line-height: 1;
            white-space: nowrap;
            width: 100%;
            margin-right: 1rem;
            overflow: hidden;
            
            & .notification-bar-message-inner {
                flex-direction: row;
                flex: 1;
                gap: 0.5rem;

                & .content {
                    display: inline-block;
                    width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            & img {
                align-self: flex-start;
            }
        }
    }
}
</style>