<template>
    <button class="close-btn" href="#" @click="closeASLRemote" aria-label="Close sign language remote">
        <svg class="icon" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
            <g>
                <path
                    d="M37.96 12.08L35.86 9.95996L23.96 21.86L12.06 9.95996L9.95996 12.08L21.86 23.96L9.95996 35.86L12.06 37.96L23.96 26.08L35.86 37.96L37.96 35.86L26.06 23.96L37.96 12.08Z" />
            </g>
        </svg>
    </button>
</template>

<script>
export default {
    name: 'ASLCloseButton',
    methods: {
        closeASLRemote: function () {
            arRouter.push('/remote');
            return;
        }
    }
};
</script>

<style lang="scss" scoped>
.close-btn {
    background-color: transparent;
    border: none;
    padding: 0;
    margin: 0;
}

.icon {
    fill: #fff;
    width: 2rem;
    height: 2rem;
}

@include md {
    .icon {
        width: 3rem;
        height: 3rem;
    }
}
</style>
