<template>
    <dialog id="asl-feedback" ref="dialog" @close="close" aria-labelledby="feedback-heading"
        @click="closeOnBackdropClick">
        <div class="dialog-content">
            <div class="dialog-content-sent" v-if="feedbackSent">
                <h1 ref="feedbackSent" tabindex="-1" id="feedback-heading">Feedback submitted!</h1>
                <div>Your feedback helps improve the accuracy of our ASL recognition.</div>
                <button id="close-sent" class="dialog-button inverse" @click="close">Done</button>
            </div>
            <div class="dialog-content-main" v-if="!feedbackSent">
                <div class="dialog-header-row-container">
                    <button @click="close" class="close" aria-label="Close">
                        <CloseIcon />
                    </button>
                    <div class="dialog-header-row">
                        <div class="header-content">
                            <div class="header-icon-container">
                                <svg class="header-icon" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                                    aria-hidden="true">
                                    <g>
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M14.94 5.24983H8.12C6.50218 5.24452 4.94851 5.8821 3.80078 7.02233C2.65306 8.16255 2.0053 9.71201 2 11.3298V14.6998V18.6998H15.2C17.0621 18.7077 18.8455 17.9493 20.1316 16.6026C21.4178 15.256 22.0934 13.4396 22 11.5798C21.6537 7.97763 18.6188 5.23322 15 5.24983H14.94ZM14.94 6.74983C17.7963 6.67415 20.2226 8.82503 20.49 11.6698C20.5864 13.1129 20.0752 14.5305 19.08 15.5798C18.0761 16.6532 16.6696 17.2585 15.2 17.2498H3.5V14.6998V14.5598V11.3298C3.50529 10.1098 3.99502 8.9419 4.86144 8.08298C5.72786 7.22407 6.9 6.74451 8.12 6.74983H14.94ZM16.39 11.2499H7.61V9.74991H16.39V11.2499ZM15.19 14.2499H7.61V12.7499H15.19V14.2499Z" />
                                    </g>
                                </svg>
                            </div>
                            <div class="dialog-header">
                                <h1 id="feedback-heading">Tell us how we did.</h1>
                                <div id="feedback-buttons-legend">How was your experience using the ASL remote?</div>
                                <div><strong>Sign recognized: </strong>{{ recognizedSign }}</div>
                            </div>
                        </div>
                        <div class="feedback-buttons-container" role="group" aria-labelledby="feedback-buttons-legend">
                            <div class="feedback-buttons">
                                <button class="dialog-button" :class="{ inverse: this.rating === 'good' }"
                                    @click="setFeedbackValue('good')" :aria-pressed="this.rating === 'good'">
                                    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                        <g>
                                            <path v-if="this.rating === 'good'"
                                                d="M7.86018 9.16043V21.8204H2.18018V9.16043H7.86018ZM21.5102 11.2704C21.3435 10.5469 20.934 9.90227 20.35 9.44378C19.766 8.9853 19.0426 8.74061 18.3002 8.75043H16.1902V7.59043C17.1002 3.27043 14.1902 2.35043 12.7102 2.19043C12.445 2.19043 12.1906 2.29579 12.0031 2.48332C11.8155 2.67086 11.7102 2.92521 11.7102 3.19043C11.8102 6.81043 10.6002 8.56043 9.40018 9.41043V21.1804H17.8702C18.5287 21.1837 19.1656 20.9453 19.6602 20.5104C20.5902 19.6704 21.8502 17.9204 21.8602 14.5704C21.8683 13.4608 21.7509 12.3537 21.5102 11.2704Z" />
                                            <path v-if="this.rating !== 'good'" fill-rule="evenodd" clip-rule="evenodd"
                                                d="M12.6002 2.17969C12.0754 2.21651 11.6689 2.65362 11.6702 3.17969C11.7825 7.54773 9.97515 9.19009 8.60018 9.8076V9.15969H2.18018V21.8197H8.60018V21.1797H17.8302C18.4887 21.183 19.1256 20.9446 19.6202 20.5097C20.5502 19.6697 21.8102 17.9197 21.8202 14.5697C21.8414 13.4615 21.7374 12.3545 21.5102 11.2697C21.1691 9.78991 19.8487 8.74359 18.3302 8.74969H16.2202V7.58969C17.1302 3.26969 14.2202 2.34969 12.7402 2.18969L12.6002 2.17969ZM7.10018 20.3197V10.6597H3.68018V20.3197H7.10018ZM8.60018 19.7397H17.8302C18.1178 19.7468 18.3971 19.6429 18.6102 19.4497C19.3902 18.7597 20.3202 17.3597 20.3202 14.6297C20.3393 13.6229 20.2488 12.6169 20.0502 11.6297C19.8566 10.837 19.1461 10.2794 18.3302 10.2797H14.7202V7.27969C15.0057 6.39398 14.9191 5.43028 14.4802 4.60969C14.1516 4.1997 13.693 3.91398 13.1802 3.79969C13.2832 6.02857 12.5199 8.21087 11.0502 9.88969C10.3731 10.5928 9.53087 11.1154 8.60018 11.4097V19.7397Z" />
                                        </g>
                                    </svg>
                                    <span>Good</span>
                                </button>
                                <button class="dialog-button" :class="{ inverse: this.rating === 'bad' }"
                                    @click="setFeedbackValue('bad')" :aria-pressed="this.rating === 'bad'">
                                    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                        <g>
                                            <path v-if="this.rating === 'bad'"
                                                d="M2.18018 2.17969H7.86018V14.8397H2.18018V2.17969ZM19.6202 3.48969C19.1256 3.05477 18.4887 2.81637 17.8302 2.81969H9.36018V14.6297C10.5602 15.4797 11.7702 17.2297 11.6702 20.8497C11.6613 20.9833 11.6811 21.1173 11.7283 21.2426C11.7754 21.3679 11.8488 21.4817 11.9434 21.5764C12.0381 21.6711 12.1519 21.7445 12.2773 21.7916C12.4026 21.8387 12.5366 21.8585 12.6702 21.8497C14.1402 21.6897 17.0602 20.7697 16.1502 16.4497V15.2297H18.2602C19.0026 15.2395 19.726 14.9948 20.31 14.5363C20.894 14.0779 21.3035 13.4332 21.4702 12.7097C21.6974 11.6249 21.8014 10.5178 21.7802 9.40969C21.8102 6.07969 20.5502 4.32969 19.6202 3.48969Z" />
                                            <path v-if="this.rating !== 'bad'" fill-rule="evenodd" clip-rule="evenodd"
                                                d="M8.60018 2.17969H2.18018V14.8397H8.60018V14.1917C9.97515 14.8093 11.7825 16.4516 11.6702 20.8197C11.6689 21.3457 12.0754 21.7828 12.6002 21.8197H12.7102C14.1802 21.6597 17.1002 20.7397 16.1902 16.4197V15.2297H18.3002C19.8187 15.2357 21.1391 14.1894 21.4802 12.7097C21.7074 11.6248 21.8114 10.5178 21.7902 9.40966C21.7902 6.05965 20.5202 4.30965 19.5902 3.46965C19.0956 3.03473 18.4587 2.79634 17.8002 2.79965L8.60018 2.81685V2.17969ZM8.60018 4.31965V12.5897C9.52629 12.9056 10.3583 13.4489 11.0202 14.1697C12.4899 15.8485 13.2532 18.0308 13.1502 20.2597C13.663 20.1454 14.1216 19.8596 14.4502 19.4497C14.8891 18.6291 14.9757 17.6654 14.6902 16.7797V13.7797H18.3102C19.1336 13.7892 19.8548 13.2296 20.0502 12.4297C20.2488 11.4424 20.3393 10.4365 20.3202 9.42966C20.3102 6.69965 19.3902 5.29965 18.6102 4.60965C18.3971 4.4164 18.1178 4.31256 17.8302 4.31965H8.60018ZM7.10018 3.67969H3.68018V13.3397H7.10018V3.67969Z" />
                                        </g>
                                    </svg>
                                    <span>Bad</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="feedback-comments-container" :class="{ visible: this.rating === 'bad' }"
                    :inert="this.rating !== 'bad'" :aria-hidden="this.rating !== 'bad'">
                    <div class="feedback-comments-labels">
                        <label for="feedback-comments">Give us a brief description of what you were searching for:</label>
                        <span id="feedback-comments-length">{{ commentChars }}/{{ commentCharsMax }} <span
                                class="screen-reader-text">characters entered</span></span>
                    </div>
                    <textarea ref="feedbackComments" id="feedback-comments" :aria-invalid="commentError"
                        aria-describedby="feedback-comments-length feedback-comments-error" v-model="comments"
                        @blur="checkforCommentError" :maxlength="commentCharsMax"></textarea>
                    <div id="feedback-comments-error" aria-live="polite" aria-atomic="true">
                        <div class="error-inner" v-if="this.commentError === true">
                            <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Error">
                                <g>
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M9.0535 2.33301H6.94683C3.84667 2.33301 1.3335 4.84618 1.3335 7.94634V8.05301C1.3335 9.54176 1.9249 10.9695 2.9776 12.0222C4.03031 13.0749 5.45808 13.6663 6.94683 13.6663H9.0535C10.5422 13.6663 11.97 13.0749 13.0227 12.0222C14.0754 10.9695 14.6668 9.54176 14.6668 8.05301V7.94634C14.6668 6.45759 14.0754 5.02982 13.0227 3.97712C11.97 2.92441 10.5422 2.33301 9.0535 2.33301ZM7.3335 4.99967H8.66683V9.04634H7.3335V4.99967ZM7.44016 11.113C7.75535 11.4077 8.24497 11.4077 8.56016 11.113C8.70999 10.9652 8.79434 10.7635 8.79434 10.553C8.79434 10.3425 8.70999 10.1408 8.56016 9.99301C8.24497 9.69834 7.75535 9.69834 7.44016 9.99301C7.29033 10.1408 7.20599 10.3425 7.20599 10.553C7.20599 10.7635 7.29033 10.9652 7.44016 11.113Z" />
                                </g>
                            </svg>
                            <span>{{ commentErrorMessage }}</span>
                        </div>
                    </div>
                </div>
                <div role="alert" aria-atomic="true"><span class="error-inner" v-if="feedbackSendingError">{{ $t("wereSorryErrorOccurred") }}</span></div>
                <button class="dialog-button inverse submit" :class="{ visible: !!rating }" :inert="!rating" :aria-disabled="sendingFeedback"
                    @click="submitFeedback">
                    <svg class="sending-spinner" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" aria-hidden="true" v-if="sendingFeedback">
						<path d="M20.27,6.37A10.35,10.35,0,0,0,19,4.89L18,6A8.6,8.6,0,0,1,19,7.22,8.5,8.5,0,1,1,12,3.5V2a10,10,0,1,0,8.27,4.37Z"/>
					</svg>
                    <span v-if="!sendingFeedback">Submit</span>
                    <span v-if="sendingFeedback">Submitting</span>
                </button>
            </div>
        </div>
    </dialog>
</template>

<script>
import CloseIcon from './CloseIcon.vue';

export default {
    name: 'ASLFeedback',
    components: {
        CloseIcon
    },
    data: function () {
        return {
            rating: null,
            comments: "",
            commentCharsMax: 500,
            commentError: false,
            commentErrorMessage: "",
            sendingFeedback: false,
            feedbackSendingError: false,
            feedbackSent: false
        }
    },
    computed: {
        commentChars() {
            return this.comments.length
        }
    },
    props: {
        recognizedSign: "",
        signID: ""
    },
    mounted: function () {
        this.$refs.dialog.showModal();
    },
    methods: {
        close() {
            this.$refs.dialog.close();
            this.$emit('dismiss-feedback');
        },

        closeOnBackdropClick(e) {
            // Close the dialog when clicking the backdrop, but not when clicking anything else.
            if (e.target === this.$refs.dialog) {
                this.close();
            }
        },

        setFeedbackValue(val) {
            this.rating = val;
            if (val === 'good') {
                this.commentError = false;
                this.commentErrorMessage = "";
            }
        },

        submitFeedback(e) {
            if(e.currentTarget.getAttribute("aria-disabled") === "true") return;
            // send feedback to vendor through api/v1/aslFeedback
            if (this.commentError === true || (this.rating === "bad" && this.checkforCommentError())) {
                this.$refs.feedbackComments.focus();
                return;
            } else {
                // Submit feedback data to vendor
                if(!["good", "bad"].includes(this.rating)) return;
                this.sendingFeedback = true;
                let feedbackData = {
                    id: this.signID,
                    good: this.rating === "good" ? true : false,
                    correction: this.rating === "good" ? "" : this.comments.trim()
                }
                try {
                    feedbackData = JSON.stringify(feedbackData)
                    ar.processASLFeedback(feedbackData, (err) => {
                        this.sendingFeedback = false;
                        if(err) {
                            this.feedbackSendingError = true;
                            this.feedbackSent = false;
                            // todo: show error message 
                        } else {
                            this.feedbackSent = true;
                            this.$nextTick(() => {
                                this.$refs.feedbackSent.focus();
                            })
                        }
                    })
                } catch (err) {
                    this.sendingFeedback = false;
                    this.feedbackSent = false;
                    this.feedbackSendingError = true;
                }
            }
        },

        checkforCommentError() {
            if (this.comments.length > this.commentCharsMax) {
                //  show error and pop message in
                this.commentError = true;
                this.commentErrorMessage = `Please limit description to ${this.commentCharsMax} characters.`;
            } else if (this.comments.length === 0 || !this.comments.match(/[a-zA-Z]/ig)) {
                this.commentError = true;
                this.commentErrorMessage = "Please include a brief description.";
            } else {
                this.commentError = false;
                this.commentErrorMessage = "";
            }
            return this.commentError;
        },
    }
}
</script>

<style lang="scss" scoped>
$fade-speed: 300ms;

@keyframes open {
    from {
        transform: translateY(100%);
    }

    to {
        transform: translateY(0%);
    }
}

@keyframes open-bg {
    from {
        opacity: 0
    }

    to {
        opacity: 0.6
    }
}

@keyframes rotate {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

#asl-feedback {
    & * {
        // Safari
        font-family: "Xfinity Brown", "xfinitybrown-regular", "DM Sans", sans-serif;
    }

    &[open] {
        @include flex-col;
        animation: open $fade-speed ease-in-out;
        gap: 1.5rem;
        box-sizing: border-box;
        background-color: $xfinity-grey-05;
        // width: 100%;
        min-height: 6.25rem;
        margin-bottom: 2.5rem;
        padding: 0;
        border: 0.0625rem solid transparent;
        border-radius: 0.5rem;
        box-shadow: none;

        @include tp {
            max-width: calc(100% - 2rem);
        }

        & :focus, h1:focus-visible  {
            @include focus-outline;
            outline-color: $xfinity-grey-80;
        }

        & h1:focus {
            outline: none;
        }
    }

    &::backdrop {
        animation: open-bg $fade-speed forwards;
        background-color: $xfinity-grey-80;
        opacity: 0.6;
    }
}

.dialog-content-main, .dialog-content-sent {
    @include flex-col;
    gap: 1.5rem;
    font-family: "DM Sans", sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    padding: 1.5rem 1rem;
}
.dialog-content-sent {
    @include md {
        align-items: flex-start;
    }
    & h1 {
        font-weight: bold;
    }
}

.header-icon {
    background-color: $xfinity-grey-80;
    border-radius: 2.5rem;
    padding: 0.5rem 0.75rem;
    height: 1.5rem;
    width: 1.5rem;
    fill: $xfinity-grey-05;
}

.close {
    background-color: transparent;
    border: none;
    padding: 0;
    margin: 0;
    line-height: 0;
}

h1 {
    font-family: "Xfinity Brown", "xfinitybrown-regular", "DM Sans", sans-serif;
    font-size: 1rem;
    font-weight: 700;
    margin: 0;
}

.dialog-header-row-container {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-start;
    gap: 1rem;

    @include md {
        align-items: center;
    }

    .dialog-header-row {
        @include flex-col;
        gap: 2rem;
        align-items: flex-start;
        justify-content: space-between;
        flex: 1;

        @include md {
            align-items: center;
            flex-direction: row;
        }

        & .header-content {
            @include flex-col;
            gap: 1rem;

            @include md {
                flex-direction: row;
            }
        }
    }
}

.feedback-buttons-container {
    @include flex-col;
    gap: 1rem;

    @include md {
        flex-direction: row;
    }
}

.feedback-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;

    @include tp {
        flex-wrap: nowrap;
    }
}

.dialog-button {
    display: flex;
    padding: 1rem 2rem;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    border-radius: 0.5rem;
    border: 0.125rem solid $xfinity-grey-80;
    background-color: $xfinity-grey-05;
    color: $xfinity-grey-80;
    text-align: center;
    font-family: "Xfinity Brown", "xfinitybrown-regular", "DM Sans", sans-serif;    
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem;
    letter-spacing: -0.0125rem;
    max-height: 3.5rem;

    &.inverse {
        background-color: $xfinity-grey-80;
        color: $xfinity-grey-05;

        & svg g {
            fill: $xfinity-grey-05;
        }
    }

    &.submit {
        overflow: hidden;

        &:not(.visible) {
            opacity: 0;
            height: 0;
            width: 0;
            padding: 0;
        }

        &[aria-disabled='true'] {
            opacity: 0.5;
            cursor: not-allowed;
        }

        @include tp {
            align-self: flex-start;
        }
    }
}

svg {
    width: 1.5rem;
    height: 1.5rem;
}


.feedback-comments-container {
    @include flex-col;
    max-height: 0;
    opacity: 0;
    overflow-y: hidden;
    gap: 0.25rem;

    &.visible {
        max-height: 30rem;
        opacity: 1;
    }

    & #feedback-comments {
        resize: none;
        height: 10rem;
        padding: 1rem;
        border: 0.0625rem solid $xfinity-grey-80;
        border-radius: 0.25rem;
        box-shadow: none;
        background-color: $xfinity-grey-05;
        font-family: "DM Sans", sans-serif;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.5rem;

        &[aria-invalid='true'] {
            border-color: $ar-asl-error-background-color;
        }
    }
}

.feedback-comments-labels {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    font-family: "DM Sans", sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem;

    & #feedback-comments-length {
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1rem;
    }
}

#feedback-comments-error {
    height: 1.5rem;
}

.error-inner {
    display: flex;
    gap: 0.25rem;
    font-size: 0.75rem;
    fill: $ar-asl-error-background-color;
    color: $ar-asl-error-background-color;
}


@media(prefers-reduced-motion: no-preference) {
    // ADD: fade-in for backdrop and fade-up for dialog

    .feedback-comments-container {
        transition: all $fade-speed linear;
    }

    .submit {
        transition: opacity $fade-speed linear;
    }

    .sending-spinner {
        fill: $xfinity-grey-05;
        animation-name: rotate;
        animation-duration: 3s;
        animation-timing-function: linear;
        animation-direction: normal;
        animation-iteration-count: infinite;
    }
}
</style>