<template>
    <svg class="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" aria-hidden="true">
        <polygon
            points="19.48 6.27 17.7 4.48 11.98 10.2 6.27 4.48 4.48 6.27 10.2 11.98 4.48 17.7 6.27 19.48 11.98 13.77 17.7 19.48 19.48 17.7 13.77 11.98 19.48 6.27" />
    </svg>
</template>

<script>
export default {
    name: 'CloseIcon'
}
</script>

<style lang="scss" scoped>
.icon {
    height: 1.5rem;
    width: 1.5rem;
}
</style>