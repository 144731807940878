<template>
	<div id="choose-auth" class="centered-container">
		<img class="svg partner-logo" v-bind:src="imgLogo" alt="logo" />

		<!--
		<div id="choose-language">
			<button
				class="language-choice"
				v-for="entry in languages"
				:key="entry.title"
				@click="changeLocale(entry.language)"
			>
				<flag :iso="entry.flag" v-bind:squared=false /> {{entry.title}}
			</button>
		</div>
		-->

		<p id="choose-auth-heading" class="standard-heading" v-bind:t="'chooseHowToSetUp'" role="heading" aria-level="1">
			{{ $t('chooseHowToSetUp') }}
		</p>

		<p id="choose-auth-text" class="standard-text">
			{{ $t('loginMessage') }}
		</p>

		<div id="choose-auth-buttons">
			<button
				id="choose-auth-login-button"
				class="standard-button primary"
				@click="handleLoginClick"
				v-t="'login'"
			/>
			<button
				v-show="shouldShowEasyPair"
				id="choose-auth-easypair-button"
				class="standard-button secondary"
				@click="handleEasyPairClick"
				v-t="'easyPair'"
			/>
		</div>
	</div>
</template>

<script>
import i18n from '@/plugins/i18n';
import { Analytics } from '../analytics.js';

export default {
	name: 'ChooseAuth',
	props: [],
	data: function () {
		return ar.state;
	},
	methods: {
		//changeLocale goes with the Flag UI

		// changeLocale: function(locale) {
		// 	i18n.locale = locale;				// Needed for <p v-t="'<msgCode>'"></p> to work
		// 	ar.saveLocal('locale', locale);
		// },

		// NOTE: The choose-auth-login-button is a link, not a button, because the Vue Router doesn't seem to play
		//       nicely with redirects. That is, if it is a button with an on-click handler that uses this.$router.push()
		//       or arRouter.push(), it doesn't work properly (you get taken to the 'You seem lost' error, despite the
		//       URL being right in the web browser's address bar).
		handleLoginClick: function() {
			Analytics.track({ eventName: 'login', properties: undefined });

			const partner = this.partner || 'comcast';
			const clientDeviceId = this.clientDeviceId;

			const queryString = window.location.search;
			const urlParams = new URLSearchParams(queryString);
			const entries = urlParams.entries();

			const qParams = [];
			qParams.push({ key: 'partner', val: partner });
			qParams.push({ key: 'clientDeviceId', val: clientDeviceId });
			for(const entry of entries) {
				qParams.push({
					key: entry[0],
					val: entry[1]
				})
			}
			const urlString = qParams.map(qp => `${qp.key}=${qp.val}`).join('&');

			const loginLink = '/login?' + urlString;

			// Interestingly, arRouter.push(loginLink) does not work! Not sure why...
			window.location.href = loginLink;
		},

		handleEasyPairClick: function () {
			Analytics.track({ eventName: 'easy_pair', properties: undefined });
			ar.startPairing();
		}
	},
	computed: {
		imgLogo: function () {
			return ar.partnerLogoUrl(this.partner);
		},
		shouldShowEasyPair: function () {
			if (this.partner === 'comcast') {
				return true;
			}
			return false;
		}
	},
	mounted: function() {
		window.focusPageHeading();
	}
};
</script>

<style lang="scss" scoped>
/* Home page when you first visit app, prior to authenticating/pairing */
#choose-auth {
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 60rem;
	height: 100%;

	p {
		margin-top: 2rem;

		&#choose-auth-heading {
			padding-left: 10%;
			padding-right: 10%;
		}
	}
}

#choose-auth-buttons {
	margin-top: 4rem;
	display: inline-flex;
	flex-wrap: wrap;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	max-width: 80%;
	row-gap: 1rem;
}

.language-choice {
	padding: 1rem;
	border: 1px solid black;
	font-size: 1rem;
	margin-left: 1rem;
	margin-right: 1rem;
}
</style>
