<template>
    <div id="asl-recording-badge">
        <div class="icon-container">
            <svg class="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" aria-hidden="true">
                <path d="M9.00023 17.51C4.31023 17.51 0.490234 13.69 0.490234 8.99999C0.490234 4.30999 4.31023 0.48999 9.00023 0.48999C13.6902 0.48999 17.5102 4.30999 17.5102 8.99999C17.5102 13.69 13.6902 17.51 9.00023 17.51ZM9.00023 1.98999C5.14023 1.98999 1.99023 5.12999 1.99023 8.99999C1.99023 12.87 5.13023 16.01 9.00023 16.01C12.8702 16.01 16.0102 12.87 16.0102 8.99999C16.0102 5.12999 12.8702 1.98999 9.00023 1.98999Z" />
                <path d="M9.00035 14.1401C11.8391 14.1401 14.1404 11.8389 14.1404 9.00011C14.1404 6.16136 11.8391 3.86011 9.00035 3.86011C6.16161 3.86011 3.86035 6.16136 3.86035 9.00011C3.86035 11.8389 6.16161 14.1401 9.00035 14.1401Z" />
            </svg>
        </div>
        <span class="label">Recording</span>
    </div>
</template>

<script>
export default {
    name: 'RecordingBadge',
    props: [],
    data: function () {
        return {}
    },
    methods: {},
    computed: {}
};
</script>

<style lang="scss" scoped>
#asl-recording-badge {
    display: inline-flex;
    flex-wrap: nowrap;
    gap: 0.25rem;
    padding: 0.25rem;
    position: absolute;
    color: $xfinity-grey-05;
    background-color: $ar-asl-error-background-color;
    border-radius: 0.125rem;
    left: 0.5rem;
    top: 0.5rem;
    line-height: 1.5;

    & .icon-container {
        display: flex;
        align-items: center;
        padding: 0.25rem;
        & .icon {
            width: 1rem;
            height: 1rem;
            fill: #fff;
        }
    }
    
    & .label {
        display: flex;
        align-items: bottom;
        font-size: 1rem;
    }
}
</style>
