import { render, staticRenderFns } from "./EditCustomButtonPage.vue?vue&type=template&id=fe2a2dec&scoped=true"
import script from "./EditCustomButtonPage.vue?vue&type=script&lang=js"
export * from "./EditCustomButtonPage.vue?vue&type=script&lang=js"
import style0 from "./EditCustomButtonPage.vue?vue&type=style&index=0&id=fe2a2dec&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../deps/0/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fe2a2dec",
  null
  
)

export default component.exports