<template>
	<div id="asl-getting-started-container">
		<div class="getting-started-contents">
			<h2 class="standard-setting getting-started-heading">Getting started</h2>
			<p>Watch this quick video to learn how to use the Sign Language remote control.</p>
			<ol>
				<li>Select "Start recording" to begin recording.</li>
				<li>Sign an ASL Sign of your search terms.</li>
				<li>Select "Stop recording" to send the command to your TV.</li>
			</ol>
		</div>
		<div class="getting-started-buttons-outer">
			<div class="getting-started-buttons">
				<button class="standard-button primary" @click="continueToASLRemote">Continue to ASL remote</button>
				<button class="standard-button secondary" @click="exitASLRemote">Exit ASL remote</button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ASLGettingStarted',
	methods: {
		continueToASLRemote: function () {
			ar.saveLocal('viewed-asl-getting-started', 'true');
			arRouter.push('/asl-interface');
			return;
		},
		exitASLRemote: function () {
			arRouter.push('/remote');
			return;
		},
	}
};
</script>

<style lang="scss" scoped>
#asl-getting-started-container {
	display: flex;
	flex-direction: column;
	margin-bottom: 15rem;
}

.getting-started-heading {
	font-family: "Xfinity Brown", "xfinitybrown-regular", "DM Sans", sans-serif;
	font-size: 2rem;
	font-style: normal;
	font-weight: 700;
	line-height: 2.5rem; /* 125% */
	letter-spacing: -0.0125rem;
	margin-left: 1rem;
	margin-bottom: 0.5rem;
}

.getting-started-contents {
	font-family: "Xfinity Brown", "xfinitybrown-light", "DM Sans", sans-serif;
	font-size: 1.5rem;
	font-style: normal;
	font-weight: 400;
	line-height: 1.5rem;
	margin-left: 1rem;
	margin-right: 1rem;
	max-width: 50rem;
	
	& ol {
		padding-left: 0;
		list-style-position: inside;
	}

	& li {
		margin-bottom: 0.5rem;
	}
}

.instructions-video-container {
	display: flex;
	margin-top: 2rem;
	margin-left: 1rem;
	margin-right: 1rem;
	height: 12rem;
	border: 0.0625rem solid $xfinity-grey-80;
}

.getting-started-buttons-outer {
	width: 100%;
	position: fixed;
	bottom: 0;
}

.getting-started-buttons {
	display: flex;
	padding: 1rem;
	flex-direction: column;
	row-gap: 0.5rem;
	margin: 1rem;
	& .standard-button {
		margin: unset;
	}
}

@include tp {
	.getting-started-heading, .getting-started-contents, .instructions-video-container {
		margin-left: 2.5rem;
		margin-right: 2.5rem;
	}

	.getting-started-contents {
		margin-bottom: unset;
	}

	.instructions-video-container {
		height: 18.25rem;
	}

	.getting-started-buttons-outer {
		position: unset;
	}

	.getting-started-buttons {
		column-gap: 1rem;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: center;
	}
}

@include md {
	.getting-started-heading, .getting-started-contents, .instructions-video-container {
		margin-left: auto;
		margin-right: auto;
	}
}

</style>
