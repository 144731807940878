import arRouter from "../../router";

export default {
	methods: {
		handleASLButtonClick: function () {
			let consented = ar.getLocal('asl-consent');
			if( consented !== 'true' ) {
				arRouter.push('/asl-consent');
			} else {
				let viewedGettingStarted = ar.getLocal('viewed-asl-getting-started');
				arRouter.push(viewedGettingStarted === 'true' ? '/asl-interface' : '/asl-getting-started');
			}
		}
	}
};