<template>
	<div class="remote-header">
		<h1 class="heading">
			{{heading}}
			<template v-if="isBeta">
				<img class="beta-badge" src="/images/beta-badge@2x.png" alt="beta" />
			</template>
		</h1>
		<div id="pwa-install" class="pwa-hide">
			<button class="pwa-prompt" v-if="deferredPrompt" @click="install">
				Add App to Homepage
			</button>
			<button class="pwa-dismiss" v-if="deferredPrompt" @click="dismiss" aria-label="Close">
				X
			</button>
		</div>
	</div>
</template>

<script>
export default {
	name: 'RemoteHeader',
	props: [ 'heading', 'isBeta' ],
	components: {
		/* RemoteNavigationDevLinks */
	},
	data: function() {
		return {
			deferredPrompt: null
		};
	},
	created: function() {
		window.addEventListener('load', () => {
			window.addEventListener("beforeinstallprompt", (e) => {
				const pwaInstall = document.getElementById('pwa-install');
				if ( ! pwaInstall ) { return; }
				const reflow = pwaInstall.offsetHeight;
				// Trigger the CSS transition
				pwaInstall.classList.add('pwa-show');

				// Prevent Chrome 76 and earlier from automatically showing a prompt
				e.preventDefault();
				// Stash the event so it can be triggered later.
				this.deferredPrompt = e;
			});
		});
		window.addEventListener("appinstalled", () => {
			this.deferredPrompt = null;
		});
	},
	methods: {
		async dismiss() {
			const pwaInstall = document.getElementById('pwa-install');
			if ( ! pwaInstall ) { return; }
			const reflow = pwaInstall.offsetHeight;
			// Trigger the CSS transition
			pwaInstall.classList.remove('pwa-show');
			const _this = this;

			setTimeout(() => {
				_this.deferredPrompt = null;
			}, 2300);
		},
		async install() {
			this.deferredPrompt.prompt();
		},
	},
	computed: {}
};
</script>

<style lang="scss" scoped>
	.remote-header {
		display: flex;
		align-items: center;
		justify-content: center;
		background: black;
        height: 4rem;
		width: 100%;

		button {
			padding: 1rem;
			bottom: 0rem;
			right: 0rem;
			position: absolute;
			background-color: black;
			color: white;
			border: 0.25rem solid;
			font-size: 1rem;
			font-weight: bold;
			text-align: start;
			cursor: pointer;
			z-index: 1;

			&:hover {
				background-color: white;
				border-color: white;
				color: black;
			}

			&::active {
				background: #e5e5e5;
				box-shadow: inset 0px 0px 5px #c1c1c1;
				outline: none;
			}

			@include md {
				top: 1rem;
				right: 3rem;
				bottom: unset;
			}
		}

		@include md {
			height: 5.5rem;
		}
	}
	#pwa-install {
		position: absolute;
		top: 0rem;
		right: 0.25rem;
		z-index: 999;

		& button[class*='pwa-'] {
			position: relative;
			top: 0.125rem;
			right: 0.5rem;
			bottom: unset;

			@include md {
				top: 0.75rem;
			}
		}
	}
	.pwa-hide {
		visibility: hidden;
		opacity: 0;
		transition: visibility 2s linear 2s, opacity 2000ms;
	}
	.pwa-show {
  		visibility: visible;
  		opacity: 1;
  		transition: visibility 0s linear 0s, opacity 2000ms;
	}
	.pwa-prompt {
		cursor: pointer;
		@include focus-pseudo-class;
	}
	.pwa-dismiss {
		@include focus-pseudo-class;
	}

	.heading {
		font-size: 1.25rem;
		line-height: 3rem;
		margin: 0;

		@include md {
			font-size: 2rem;
		}
    }
    .textBlur .remote-header .heading {
		color: transparent;
		text-shadow: 0 0 10px white
	}
	.beta-badge {
		width: 2rem;
		vertical-align: middle;
		margin-left: 10px;
	}
</style>
